import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { IRootState, IMerchantState } from '@/models/Store.model'

type TAction = ActionTree<IMerchantState, IRootState>
type TActionContext = ActionContext<IMerchantState, IRootState>
type TMutation = MutationTree<IMerchantState>
type TGetter = GetterTree<IMerchantState, IRootState>

const state: IMerchantState = {
  merchant: {},
  token: {},
  user: {}
}

const actions: TAction = {
  setUser ({ commit }: TActionContext, payload: any): void {
    commit('SET_USER', payload)
  },
  setAccessToken ({ commit }: TActionContext, payload: any): void {
    commit('SET_ACCESS_TOKEN', payload)
  },
  setMerchant ({ commit }: TActionContext, payload: any): void {
    commit('SET_MERCHANT', payload)
  },
  clearUser ({ commit }: TActionContext): void {
    commit('SET_USER', null)
  },
  clearAccessToken ({ commit }: TActionContext): void {
    commit('SET_ACCESS_TOKEN', null)
  },
  clearMerchant ({ commit }: TActionContext): void {
    commit('SET_MERCHANT', null)
  },
  clearAll ({ commit }: TActionContext): void {
    commit('SET_USER', null)
    commit('SET_ACCESS_TOKEN', null)
    commit('SET_MERCHANT', null)
  }
}

const mutations: TMutation = {
  SET_USER (state: IMerchantState, payload: any): void {
    state.user = payload
  },
  SET_ACCESS_TOKEN (state: IMerchantState, payload: any): void {
    state.token = payload
  },
  SET_MERCHANT (state: IMerchantState, payload: any): void {
    state.merchant = payload
  }
}

const getters: TGetter = {
  getUser: (state: IMerchantState): any => state.user,
  getUserRoles: (state: IMerchantState): string | undefined => state.user?.merchantRole,
  getToken: (state: IMerchantState): any => state.token,
  getMerchant: (state: IMerchantState): any => state.merchant,
  accessToken: (state: IMerchantState): any => state.token?.accessToken || null,
  tokenExpire: (state: IMerchantState): any => state.token?.expireIn || 0
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
