<template>
  <v-app-bar
    id="navbar"
    app
    elevate-on-scroll>
    <v-app-bar-nav-icon
      v-if="isMobile || isOpen"
      color="white"
      @click="openSideBar()" />

    <div
      :key="title"
      class="d-flex gap-4 align-center">
      <v-btn
        :ripple="false"
        depressed
        icon
        width="40"
        height="40"
        class="icon-base gradient-icon">
        <v-icon color="white">
          {{ icon }}
        </v-icon>
      </v-btn>
      <span class="_fs-8 font-weight-medium white--text">{{ title }}</span>
    </div>

    <v-spacer />

    <div class="d-flex align-center gap-2">
      <v-card
        height="44px"
        flat
        rounded
        class="d-flex align-center warehouse-selection">
        <SelectInput
          :value="currentWarehouse"
          :items="warehouseItems"
          label="สาขา"
          item-text="name"
          item-value="id"
          return-object
          @input="onWarehouseChange($event)" />
      </v-card>
      <v-card
        height="44px"
        flat
        rounded
        class="d-flex px-2 gap-2 py-1 pr-4">
        <v-avatar
          size="36">
          <img
            :src="user?.image"
            alt="profile image"
            @error="$onImageError.onImageError($event)">
        </v-avatar>
        <div class="d-flex flex-column">
          <div class="d-flex gap-2 name primary--text">
            <span>
              {{ user?.firstName || '-' }}
            </span>
            <span :class="{'hidden': isMobile}">
              {{ user?.lastName || '' }}
            </span>
          </div>
          <span class="info--text role">
            {{ formatMerchantRole(user?.merchantRole) }}
          </span>
        </div>
      </v-card>
      <v-btn
        :to="{name: 'Logout'}"
        color="white"
        rounded
        outlined
        class="ml-2 logout-btn my-3">
        <v-icon color="white">
          mdi-logout
        </v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue'
import { IMenu } from '@/models/Menu.model'
import SelectInput from './input/SelectInput.vue'
import WarehouseProvider from '@/resources/provider/Warehouse.provider'
import { IBaseSuccessResponse } from '@/models/response/Response.model'
import { TMerchantRole } from '@/models/Setting.model'

const WarehouseService = new WarehouseProvider()

interface IData {
  avatarMenus: IMenu[]
}

export default Vue.extend({
  name: 'AppBar',
  components: {
    SelectInput
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data (): IData {
    return {
      avatarMenus: [
        {
          title: 'บัญชีของฉัน',
          to: 'SettingMyAccount',
          icon: 'mdi-account-outline'
        },
        {
          title: 'ออกจากระบบ',
          to: 'LogoutPage',
          icon: 'mdi-logout'
        }
      ]
    }
  },
  computed: {
    title (): string {
      return this.$route.meta?.title || ''
    },
    icon (): any {
      return this.$route.meta?.icon || 'mdi-view-dashboard-outline'
    },
    user (): any {
      return this.$store.getters['Merchant/getUser']
    },
    userEmailFirst (): string {
      if (this.user?.email) {
        return this.user?.email[0].toUpperCase()
      }
      return ''
    },
    currentWarehouse (): any {
      return this.$store.getters['Warehouse/getWarehouse']
    },
    warehouseItems (): any {
      return this.$store.getters['Warehouse/getWarehouseItems']
    },
    warehouseItemIds (): any {
      return this.$store.getters['Warehouse/getWarehouseItemIds']
    },
    layoutName (): string {
      const layoutName: string = this.$route?.meta?.layout?.toLowerCase() || 'default'
      return layoutName
    }
  },
  watch: {
    layoutName (): void {
      this.getWarehouse()
    }
  },
  mounted (): void {
    this.getWarehouse()
  },
  methods: {
    async getWarehouse (): Promise<void> {
      try {
        const { data }: IBaseSuccessResponse = await WarehouseService.getWarehouse({
          page: 1,
          limit: 99
        })
        this.$store.dispatch('Warehouse/setWarehouseItems', data)
        if (!this.warehouseItemIds.includes(this.currentWarehouse.id)) {
          this.$store.dispatch('Warehouse/setWarehouse', data[0])
        }
      } catch (err: any) {
        this.$notify({
          title: 'เกิดข้อผิดพลาด',
          text: err?.message || 'กรุณาลองใหม่อีกครั้ง',
          type: 'error'
        })
      }
    },
    onWarehouseChange (event: any): void {
      this.$store.dispatch('Warehouse/setWarehouse', event)
    },
    openSideBar (): void {
      this.$emit('click:nav-icon')
    },
    formatMerchantRole (role: TMerchantRole): string {
      switch (role) {
        case 'SUPER_ADMIN': return 'Super admin'
        case 'ADMIN': return 'Admin'
        case 'SALE': return 'Sale'
        default: return 'GUEST'
      }
    }
  }
})
</script>

<style lang="scss" scoped>
#navbar {
  border-left: 1px solid white;
  box-shadow: 0px 3px 35px 0px #6155A633 !important;
  color: white;
  padding: 0 24px;
  background-color: var(--v-primary-base);
  border-radius: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) transform,
              0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color,
              0.2s cubic-bezier(0.4, 0, 0.2, 1) left,
              0.2s cubic-bezier(0.4, 0, 0.2, 1) right,
              280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow,
              0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width,
              0.25s cubic-bezier(0.4, 0, 0.2, 1) width,
              0.2s cubic-bezier(0.4, 0, 0.2, 1) border-radius;
  :deep(.v-toolbar__content) {
    transition: padding .2s ease;
    padding: 12px 0px;
  }
  &.v-app-bar--is-scrolled {
    backdrop-filter: blur(6px);
    :deep(.v-toolbar__content) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
.avatar-menu :deep(.v-list-item__icon) {
  margin-right: 16px;
}

.small-title {
  padding: 0 !important;
}

.name {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.role {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
}

.logout-btn {
  height: 44px !important;
  min-width: 0px !important;
  aspect-ratio: 1/1;
  border-radius: 4px;
}

.warehouse-selection {
  width: 200px;
  background: transparent;

  :deep(*) {
    color: white !important;
    border-color: white !important;
    outline-color: white !important;

    ::placeholder {
      color: white !important;
    }

    label {
      color: white !important;
    }
  }
}
</style>
