export default {
  path: 'import',
  component: (): any => import('@/views/stock/pages/import/Import.vue'),
  children: [
    {
      path: '',
      name: 'StockImport',
      component: (): any => import('@/views/stock/pages/import/pages/ImportList.vue'),
      meta: {
        auth: true,
        title: 'นำเข้าสินค้า',
        isMenu: true,
        hiddenIcon: 'mdi-warehouse',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'StockImportCreate',
      component: (): any => import('@/views/stock/pages/import/pages/ImportCreate.vue'),
      meta: {
        auth: true,
        title: 'สร้างใบนำเข้าสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'edit/:id',
      name: 'StockImportEdit',
      component: (): any => import('@/views/stock/pages/import/pages/ImportEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขรายละเอียดใบนำเข้าสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'info/:id',
      name: 'StockImportInfo',
      component: (): any => import('@/views/stock/pages/import/pages/ImportInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดใบนำเข้าสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
