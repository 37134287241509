export default {
  path: 'profile',
  component: (): any => import('@/views/setting/pages/profile/Profile.vue'),
  meta: {
    title: 'โปรไฟล์',
    auth: true,
    icon: 'mdi-account-cog-outline',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN']
  },
  children: [
    {
      path: '',
      name: 'SettingProfile',
      component: (): any => import('@/views/setting/pages/profile/pages/SettingProfile.vue'),
      meta: {
        auth: true,
        isSettingMenu: true,
        title: 'รายละเอียดผู้ใช้งาน',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'profile/edit',
      name: 'SettingProfileEdit',
      component: (): any => import('@/views/setting/pages/profile/pages/SettingProfileEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขข้อมูลส่วนตัว',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'password',
      name: 'SettingPassword',
      component: (): any => import('@/views/setting/pages/profile/pages/SettingPassword.vue'),
      meta: {
        auth: true,
        isSettingMenu: true,
        title: 'เปลี่ยนรหัสผ่าน',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
