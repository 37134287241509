export default {
  path: '/POS',
  component: (): any => import('@/views/POS/POS.vue'),
  meta: {
    auth: true,
    title: 'POS',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
  },
  children: [
    {
      path: 'list',
      name: 'POSList',
      component: (): any => import('@/views/POS/pages/POSProductList.vue'),
      meta: {
        auth: true,
        title: 'POS',
        icon: 'mdi mdi-printer-pos',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'total-summary',
      name: 'POSTotalSummary',
      component: (): any => import('@/views/POS/pages/POSTotalSummary.vue'),
      meta: {
        auth: true,
        title: 'POS',
        icon: 'mdi mdi-printer-pos',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'check-bill',
      name: 'POSCheckBill',
      component: (): any => import('@/views/POS/pages/POSCheckBill.vue'),
      meta: {
        auth: true,
        title: 'POS',
        icon: 'mdi mdi-printer-pos',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'check-cash',
      name: 'POSPayCash',
      component: (): any => import('@/views/POS/pages/POSPayCash.vue'),
      meta: {
        auth: true,
        title: 'POS',
        icon: 'mdi mdi-printer-pos',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    }
  ]
}
