<template>
  <v-navigation-drawer
    id="nav-sidebar"
    :value="!isMobile ? true : value"
    :mini-variant="!isMobile ? isMini : false"
    mini-variant-width="68"
    mobile-breakpoint="800"
    width="260"
    app
    @input="closeSideBar($event)">
    <v-list-item class="sidebar-header mb-0">
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="{ name: 'HomePage' }">
            <img
              src="/images/seoul-studio-logo.svg"
              :width="!isMini || isMobile
                ? 180
                : 100"
              height="60"
              alt="seoul studio Logo">
          </router-link>
        </v-list-item-title>
        <v-btn
          v-if="isMobile"
          :ripple="false"

          absolute
          right
          icon
          @click="closeSideBar(false)">
          <v-icon color="white">
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          :ripple="false"
          color="white"
          absolute
          right
          icon
          class="icon-base gradient-icon"
          @click="toggleIsMini()">
          <v-icon
            :class="{'is-mini': isMini}"
            color="white"
            small>
            mdi-menu-open
          </v-icon>
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <v-divider
      color="white"
      class="mx-4" />

    <v-list
      dense
      nav>
      <v-list-item-group
        v-for="(item, index) in topMenu"
        :key="`menu-${index}`"
        no-action
        mandatory>
        <ListMenu
          v-if="item.group"
          :title="item.group"
          :hidden="isMobile ? false : isMini"
          is-title />
        <v-list-group
          v-if="item.subMenus"
          :value="true"
          no-action>
          <template #activator>
            <v-list-item-icon
              v-if="item.icon"
              class="mr-2 icon-base">
              <v-icon color="white">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </template>
          <ListMenu
            v-for="(subMenu, i) in item.subMenus"
            :key="`${item.title}-${subMenu.title}-${i}`"
            :title="subMenu.title"
            :to="subMenu.to"
            :icon="subMenu.icon"
            icon-prepend
            is-submenu />
        </v-list-group>
        <ListMenu
          v-else
          :title="item.title"
          :to="item.to"
          :icon="item.icon"
          icon-prepend
          class="drawer-item" />
      </v-list-item-group>
    </v-list>

    <template #append>
      <v-list
        dense
        nav>
        <v-list-item-group
          v-for="(item, index) in bottomMenu"
          :key="`menu-${index}`"
          no-action
          mandatory>
          <ListMenu
            v-if="item.group"
            :title="item.group"
            :hidden="isMobile ? false : isMini"
            is-title />
          <v-list-group
            v-if="item.subMenus"
            :value="false"
            no-action>
            <template #activator>
              <v-list-item-icon
                v-if="item.icon"
                class="mr-2 icon-base">
                <v-icon color="white">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </template>
            <ListMenu
              v-for="(subMenu, i) in item.subMenus"
              :key="`${item.title}-${subMenu.title}-${i}`"
              :title="subMenu.title"
              :to="subMenu.to"
              :icon="subMenu.icon"
              icon-prepend
              is-submenu />
          </v-list-group>
          <ListMenu
            v-else
            :title="item.title"
            :to="item.to"
            :icon="item.icon"
            icon-prepend
            class="drawer-item" />
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue'
import { createMenus } from '@/utils/CreateMenus'
import { IMenu } from '@/models/Menu.model'
import { routesConfig } from '@/router'
import ListMenu from '@/components/ListMenu.vue'

interface IData {
  navigationDrawerValue: boolean
  isMini: boolean
  menus: IMenu[]
}

export default Vue.extend({
  name: 'NavigationDrawer',
  components: {
    ListMenu
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data (): IData {
    return {
      navigationDrawerValue: false,
      isMini: false,
      menus: []
    }
  },
  computed: {
    topMenu (): IMenu[] {
      return this.menus?.filter((items: IMenu): boolean => !items?.bottom || false) || []
    },
    bottomMenu (): IMenu[] {
      return this.menus?.filter((items: IMenu): boolean => items?.bottom || false) || []
    }
  },
  mounted (): void {
    this.menus = createMenus(routesConfig)
  },
  methods: {
    toggleIsMini (): void {
      this.isMini = !this.isMini
    },
    closeSideBar (value: boolean): void {
      if (!value) {
        this.$emit('click:close')
      }
    }
  }
})
</script>
