export default {
  path: 'export',
  component: (): any => import('@/views/stock/pages/export/Export.vue'),
  children: [
    {
      path: '',
      name: 'StockExport',
      component: (): any => import('@/views/stock/pages/export/pages/ExportList.vue'),
      meta: {
        auth: true,
        title: 'นำออกสินค้า',
        isMenu: true,
        hiddenIcon: 'mdi-warehouse',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'StockExportCreate',
      component: (): any => import('@/views/stock/pages/export/pages/ExportCreate.vue'),
      meta: {
        auth: true,
        title: 'สร้างใบนำออกสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'edit/:id',
      name: 'StockExportEdit',
      component: (): any => import('@/views/stock/pages/export/pages/ExportEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขรายละเอียดใบนำออกสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'info/:id',
      name: 'StockExportInfo',
      component: (): any => import('@/views/stock/pages/export/pages/ExportInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดใบนำออกสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
