export default {
  path: '/customer',
  component: (): any => import('@/views/customer/Customer.vue'),
  meta: {
    auth: true,
    title: 'customer'
  },
  children: [
    {
      path: 'list',
      name: 'Customer',
      component: (): any => import('@/views/customer/pages/CustomerList.vue'),
      meta: {
        auth: true,
        title: 'รายชื่อลูกค้า',
        icon: 'mdi-account-multiple-outline',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'info/:id',
      name: 'CustomerInfo',
      component: (): any => import('@/views/customer/pages/CustomerInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดลูกค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'edit/:id',
      name: 'CustomerEdit',
      component: (): any => import('@/views/customer/pages/CustomerEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขรายละเอียดลูกค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'CustomerCreate',
      component: (): any => import('@/views/customer/pages/CustomerCreate.vue'),
      meta: {
        auth: true,
        title: 'เพิ่มลูกค้าใหม่',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
