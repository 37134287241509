export default {
  path: '/product',
  component: (): any => import('@/views/product/Product.vue'),
  meta: {
    auth: true,
    title: 'product',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN']
  },
  children: [
    {
      path: 'list',
      name: 'Product',
      component: (): any => import('@/views/product/pages/ProductList.vue'),
      meta: {
        auth: true,
        title: 'รายการสินค้า',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'info/:id',
      name: 'ProductInfo',
      component: (): any => import('@/views/product/pages/ProductInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'edit/:id',
      name: 'ProductEdit',
      component: (): any => import('@/views/product/pages/ProductEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขรายละเอียดสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'ProductCreate',
      component: (): any => import('@/views/product/pages/ProductCreate.vue'),
      meta: {
        auth: true,
        title: 'เพิ่มสินค้าใหม่',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'qr-code',
      name: 'ProductQr',
      component: (): any => import('@/views/product/pages/ProductQr.vue'),
      meta: {
        auth: true,
        title: 'QR Code สินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'qr-code-result',
      name: 'ProductQrResult',
      component: (): any => import('@/views/product/pages/ProductQrResult.vue'),
      meta: {
        auth: true,
        layout: 'blank',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'qr-code-result-sticker',
      name: 'ProductQrResultSticker',
      component: (): any => import('@/views/product/pages/ProductQrResultSticker.vue'),
      meta: {
        auth: true,
        layout: 'blank',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
