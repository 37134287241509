export default {
  path: '/broadcast',
  component: (): any => import('@/views/broadcast/broadcast.vue'),
  meta: {
    auth: true,
    title: 'Broadcast'
  },
  children: [
    {
      path: 'list',
      name: 'Broadcast',
      component: (): any => import('@/views/broadcast/pages/BroadcastDashboard.vue'),
      meta: {
        auth: true,
        title: 'บรอดแคสต์ข้อความ',
        icon: 'mdi-list-box-outline',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'BroadcastCreate',
      component: (): any => import('@/views/broadcast/pages/BroadcastCreate.vue'),
      meta: {
        auth: true,
        title: 'เพิ่มข้อความ',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'detail/:id',
      name: 'BroadcastDetail',
      component: (): any => import('@/views/broadcast/pages/BroadcastDetail.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดข้อความ',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
