import Vue from 'vue'
import * as dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/th'

dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(buddhistEra)

dayjs.locale('th')

Vue.prototype.$dayjs = dayjs

export {
  dayjs
}

export default { dayjs }
