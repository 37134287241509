import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { IRootState } from '@/models/Store.model'
import { vuexUser } from '@/plugins/VuexPersist'

import LoadingModule from './loading'
import UserModule from './user'
import MerchantModule from './merchant'
import WarehouseModule from './warehouse'
import CartModule from './cart'

Vue.use(Vuex)

const store: StoreOptions<IRootState> = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Loading: LoadingModule,
    User: UserModule,
    Merchant: MerchantModule,
    Warehouse: WarehouseModule,
    Cart: CartModule
  },
  plugins: [
    vuexUser.plugin
  ]
}

export default new Vuex.Store(store)
