<template>
  <v-app
    v-if="isAuth"
    id="app">
    <AppBar
      :is-open="isOpen"
      :is-mobile="isMobile"
      @click:nav-icon="toggleSideBar(true)" />
    <NavigationDrawer
      :value="isOpen"
      :is-mobile="isMobile"
      @click:close="toggleSideBar(false)" />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <Loading />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/Loading.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'

interface IData {
  isOpen: boolean
}

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    AppBar,
    Loading,
    NavigationDrawer
  },
  data (): IData {
    return {
      isOpen: false
    }
  },
  computed: {
    isAuth (): boolean {
      return !!this.$route.meta?.auth
    },
    isMobile (): boolean {
      return this.$vuetify.breakpoint.mobile
    },
    routeData (): any {
      return this.$route.meta
    }
  },
  methods: {
    toggleSideBar (value: boolean): void {
      this.isOpen = !!value
    }
  }
})
</script>

<style lang="scss" scoped>
#app {
  :deep(.v-main>.v-main__wrap) {
    padding: 24px;
  }
}
</style>
