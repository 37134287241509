import Vue from 'vue'

const emailRegex: RegExp = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+$/
const passwordRegex: RegExp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/
const capitalRegex: RegExp = /[A-Z]/
const numericRegex: RegExp = /[0-9]/

export interface IValidate {
  required (val: string): any
  email (val: string): any
  password (val: string): any
  confirmPassword (val: string, password: string): any
  length (val: string, length: number): any
  minLength (val: string, length: number): any
  maxLength (val: string, length: number): any,
  between (val: string, min: number, max: number): any
  atLeastOne (val: any[]): any
  notExist (val: string, list: any[], initValue?: string): any
  hasCapital (val: string): string | boolean
  hasNumeric (val: string): string | boolean
}

export const validate: IValidate = {
  required: (val: string): any => !!val || 'จำเป็นต้องกรอก',
  email: (val: string): any => emailRegex.test(val) || 'อีเมลไม่ถูกต้อง',
  password: (val: string): any => passwordRegex.test(val) || 'รูปแบบรหัสผ่านไม่ถูกต้อง',
  confirmPassword: (val: string, password: string): any => val === password || 'รหัสผ่านไม่ตรงกัน',
  length: (val: string, length: number): any => (!!val && val.length === length) || `ต้องมีความยาว ${length} ตัวอักษร`,
  minLength: (val: string, length: number): any => (!!val && val.length >= length) || `ต้องกรอกอย่างน้อย ${length} ตัวอักษร`,
  maxLength: (val: string, length: number): any => (!!val && val.length <= length) || `ต้องกรอกไม่เกิน ${length} ตัวอักษร`,
  between: (val: string, min: number, max: number): any => (!!val && val.length >= min && val.length <= max)
  || `ต้องเป็นค่าระหว่าง ${min} และ ${max} ตัวอักษร`,
  atLeastOne: (val: any[]): any => !!val.length || 'จำเป็นต้องกรอกอย่างน้อย 1 ตัวเลือก',
  notExist: (val: string, list: any[], initValue?: string): any => val === initValue || !list.find((item: any): any => item?.name === val) || 'มีค่าที่กรอกในรายการแล้ว',
  hasCapital: (val: string): string | boolean => capitalRegex.test(val) || 'ต้องมีอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว',
  hasNumeric: (val: string): string | boolean => numericRegex.test(val) || 'ต้องมี 0-9 อย่างน้อย 1 ตัว'
}

Vue.prototype.$validate = validate
