import VuexPersistence from 'vuex-persist'
import { IRootState } from '@/models/Store.model'

export const vuexUser: VuexPersistence<IRootState> = new VuexPersistence({
  key: '_seoul_studio_user',
  saveState: (key: string, state: IRootState): void => {
    const stateUser: any = state.User
    const stateMerchant: any = state.Merchant
    const data = JSON.stringify({
      User: {
        ...stateUser
      },
      Merchant: {
        ...stateMerchant
      }
    })
    localStorage.setItem(key, data)
  },
  restoreState: (key: string, storage: Storage | undefined): IRootState => {
    if (storage && storage[key]) {
      const data = JSON.parse(storage[key])
      return {
        User: data?.User || {},
        Merchant: data?.Merchant || {}
      }
    }
    return {}
  },
  modules: ['User', 'Merchant']
})

export default {
  vuexUser
}
