export default {
  path: 'overview',
  component: (): any => import('@/views/stock/pages/overview/Overview.vue'),
  children: [
    {
      path: '',
      name: 'StockOverview',
      component: (): any => import('@/views/stock/pages/overview/pages/OverviewList.vue'),
      meta: {
        auth: true,
        title: 'ภาพรวมสต็อก',
        isMenu: true,
        hiddenIcon: 'mdi-warehouse',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'info/:id',
      name: 'StockOverviewInfo',
      component: (): any => import('@/views/stock/pages/overview/pages/OverviewInfo.vue'),
      meta: {
        auth: true,
        title: 'สต็อกสินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    }
  ]
}
