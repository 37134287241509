<template>
  <v-app>
    <v-fade-transition>
      <router-view />
    </v-fade-transition>
    <Loading />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import Loading from '@/components/Loading.vue'

export default Vue.extend({
  name: 'LayoutBlank',
  components: {
    Loading
  }
})
</script>
