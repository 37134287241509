import Vue from 'vue'

export interface IOnImageError {
  onImageError(event: any): void
}

export const onImageError = {
  onImageError (event: any): void {
    // eslint-disable-next-line no-param-reassign
    event.target.src = '/images/icon/image-placeholder.svg'
  }
}

Vue.prototype.$onImageError = onImageError

export default onImageError
