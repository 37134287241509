import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 800
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: '#1E1E1E',
        secondary: '#362B28',
        accents: '#2F80ED',
        info: '#94A3B8',
        success: '#27AE60',
        warning: '#F2994A',
        error: '#EB5757',
        disabled: '#CCD6E0',
        gray: '#333333',
        gray2: '#9B9DA2',
        gray3: '#828282',
        lightGray: '#E3E3E3',
        yellow: '#F2C94C',
        black: '#20293A',
        primarySeoul: '#362B28',
        blue: '#3762CC'
      }
    }
  }
})
