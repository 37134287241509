<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="items"
    :rules="rules"
    :placeholder="placeholder"
    :disabled="disabled"
    :return-object="returnObject"
    append-icon="mdi mdi-chevron-down"
    menu-props="offset-y, bottom"
    hide-details
    outlined
    dense
    @input="onInputChange($event)" />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: [String, Number, Object],
      default: null
    },
    placeholder: {
      type: String,
      default: 'กรุณาเลือก'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: (): any[] => []
    },
    items: {
      type: Array,
      default: (): any[] => []
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  data (): any {
    return {
      menu: false
    }
  },
  methods: {
    onInputChange (value: string | number): void {
      this.$emit('input', value)
    }
  }
})
</script>
