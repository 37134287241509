<template>
  <v-list-item
    :to="target"
    :link="!!to"
    :class="{
      'pl-12': isSubmenu,
      'isTitle': isTitle,
      'hidden': hidden
    }"
    :disabled="isTitle"
    class="menu"
    exact
    exact-path
    @click="onAction()">
    <v-list-item-icon
      v-if="iconPrepend && icon"
      class="mr-2 icon-base">
      <v-icon color="white">
        {{ icon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title>
      {{ title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ListMenu',
  props: {
    code: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    to: {
      type: [Object, String],
      default: undefined
    },
    query: {
      type: Object,
      default: null
    },
    param: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: undefined
    },
    iconPrepend: {
      type: Boolean,
      default: false
    },
    isSubmenu: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRoutePath (): Boolean {
      return this.to?.includes('/')
    },
    getQuery (): any {
      let query: any = {}
      if (this.code) {
        query = {
          id: this.code
        }
      }
      if (this.query) {
        query = {
          ...query,
          ...this.query,
          id: this.code
        }
      }
      return query
    },
    target (): any {
      if (!this.to) {
        return null
      }
      if (this.isRoutePath) {
        return {
          path: this.to,
          query: this.getQuery,
          params: {
            id: this.param
          } }
      }
      return {
        name: this.to,
        query: this.getQuery,
        params: {
          id: this.param
        }
      }
    }
  },
  methods: {
    onAction (): void {
      if (!this.to) {
        this.$emit('onAction')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.isTitle {
  font-size: 12px;
  font-weight: 600;
}
</style>
