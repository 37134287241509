import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import AuthRouter from './modules/Auth.router'
import POSRouter from './modules/POS.router'
import ReportRouter from './modules/Report.router'
import StockRouter from './modules/stock/Stock.router'
import OrderRouter from './modules/Order.router'
import CustomerRouter from './modules/Customer.router'
import SettingRouter from './modules/setting/Setting.router'
import BroadcastRouter from './modules/Broadcast.router'
import { getUserAccessToken, getUserRoles } from '@/utils/Authentication'

Vue.use(VueRouter)

export const routesConfig: RouteConfig[] = [
  {
    path: '/',
    name: 'HomePage',
    component: (): any => import('@/views/HomePage.vue'),
    meta: {
      auth: true,
      layout: 'default'
    }
  },
  POSRouter,
  ReportRouter,
  CustomerRouter,
  AuthRouter,
  StockRouter,
  OrderRouter,
  BroadcastRouter,
  SettingRouter,
  {
    path: '/*',
    name: 'PageNotFound',
    component: (): any => import('@/views/errorPage/404.vue'),
    meta: {
      layout: 'default'
    }
  }
]

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesConfig
})

const DEFAULT_TITLE: string = 'Seoul studio Management'

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): any => {
  const userToken = getUserAccessToken()
  if (to.meta?.auth) {
    if (!userToken) {
      return router.replace({ name: 'Logout' })
    }

    const role = getUserRoles()
    if (!to?.meta?.allowedRoles || to?.meta?.allowedRoles?.includes(role)) {
      return next()
    }
    return router.replace({ name: 'HomePage' })
  }

  if ((to?.name === 'Register' || to?.name === 'Login') && userToken) {
    return router.replace({ name: 'HomePage' })
  }
  return next()
})

router.afterEach((to: Route): void => {
  Vue.nextTick((): void => {
    document.title = to?.meta?.title ? `${DEFAULT_TITLE} - ${to.meta.title}` : DEFAULT_TITLE
  })
})

export default router
