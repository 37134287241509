<template>
  <v-overlay
    :value="isLoading"
    z-index="9999999">
    <v-progress-circular
      indeterminate
      size="64" />
  </v-overlay>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LoadingComponent',
  computed: {
    isLoading (): boolean {
      return this.$store.getters['Loading/isLoading']
    }
  }
})
</script>
