import Vue from 'vue'
import Notifications from 'vue-notification'
import App from '@/App.vue'
import Router from '@/router'
import Store from '@/store'

import Vuetify from '@/plugins/Vuetify'

import '@/assets/css/style.scss'

import '@/plugins/Dayjs'
import '@/plugins/Keypress'
import '@/plugins/Filter'
import '@/plugins/PerfectScrollbar'
import '@/plugins/Validate'
import '@/plugins/OnImageError'

Vue.use(Notifications)
Vue.config.productionTip = false

new Vue({
  router: Router,
  store: Store,
  vuetify: Vuetify,
  render: (h: any): any => h(App)
}).$mount('#app')
