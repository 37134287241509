<template>
  <div>
    <notifications />
    <Default
      v-if="layoutName === 'default'"
      class="layout-default" />
    <Auth
      v-else-if="layoutName === 'auth'"
      class="layout-auth" />
    <Blank
      v-else-if="layoutName === 'blank'"
      class="layout-blank" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Default from '@/layouts/Default.vue'
import Auth from '@/layouts/Auth.vue'
import Blank from '@/layouts/Blank.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Default,
    Auth,
    Blank
  },
  computed: {
    layoutName (): string {
      const layoutName: string = this.$route?.meta?.layout?.toLowerCase() || 'default'
      return layoutName
    }
  }
})
</script>
