import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { IRootState, ICartState, IOrderResponse } from '@/models/Store.model'

type TAction = ActionTree<ICartState, IRootState>
type TActionContext = ActionContext<ICartState, IRootState>
type TMutation = MutationTree<ICartState>
type TGetter = GetterTree<ICartState, IRootState>

const state: ICartState = {
  cart: {
    paymentMethod: '',
    discount: 0,
    totalPrice: 0,
    totalWithDiscount: 0,
    paidByCustomer: 0,
    change: 0,
    customerId: '',
    customerName: '',
    warehouseId: '',
    excludeDiscount: 0
  },
  product: [],
  orderResponse: {
    id: null,
    discount: 0,
    merchantId: null,
    orderNo: null,
    paidByCustomer: 0,
    paymentDate: new Date(),
    paymentMethod: '',
    products: [],
    totalPrice: 0,
    excludeDiscount: 0,
    warehouseId: 0
  }
}

const actions: TAction = {
  setCart ({ commit }: TActionContext, payload: any): void {
    commit('SET_CART', payload)
  },
  setSummary ({ commit }: TActionContext, payload: any): void {
    commit('SET_SUMMARY', payload)
  },
  setExcludeDiscount ({ commit }: TActionContext, discount: number): void {
    commit('SET_EXCLUDE_DISCOUNT', discount)
  },
  setOrderResponse ({ commit }: TActionContext, payload: IOrderResponse): void {
    commit('SET_RESPONSE_ORDER', payload)
  },
  calTotalWithDiscount ({ commit }: TActionContext): void {
    commit('CAL_TOTAL_DISCOUNT')
  }
}

const mutations: TMutation = {
  SET_CART (state: ICartState, payload: any): void {
    state.product = payload
  },
  SET_SUMMARY (state: ICartState, payload: any): void {
    state.cart = {
      discount: payload.discount,
      totalPrice: payload.totalPrice,
      paymentMethod: '',
      paidByCustomer: payload.paidByCustomer || 0,
      totalWithDiscount: payload.totalWIthDiscount || 0,
      change: payload.change || 0,
      customerId: payload.customerId,
      customerName: payload.customerName,
      warehouseId: ''
    }
  },
  SET_EXCLUDE_DISCOUNT (state: ICartState, discount: number): void {
    state.cart.excludeDiscount = discount
  },
  SET_RESPONSE_ORDER (state: ICartState, payload: any): void {
    state.orderResponse = payload
  },
  CAL_TOTAL_DISCOUNT (state: ICartState): void {
    state.cart.totalWithDiscount = state.cart.totalPrice - ((state.cart.discount || 0) + (state.cart.excludeDiscount || 0))
  }
}

const getters: TGetter = {
  getCart: (state: ICartState): any => state.product,
  getSummary: (state: ICartState): any => state.cart,
  getOrderResponse: (state: ICartState): any => state.orderResponse
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
