import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { IUserState, IRootState } from '@/models/Store.model'

type TAction = ActionTree<IUserState, IRootState>
type TActionContext = ActionContext<IUserState, IRootState>
type TMutation = MutationTree<IUserState>
type TGetter = GetterTree<IUserState, IRootState>

const state: IUserState = {
  token: {
    // accessToken: '',
    // expireIn: 0
  },
  user: {
    // id: 31,
    // firstName: 'John',
    // lastName: 'Doe',
    // email: 'dev@dev.com',
    // role: 'ADMIN'
  }
}

const actions: TAction = {
  setUser ({ commit }: TActionContext, payload: any): void {
    commit('SET_USER', payload)
  },
  setAccessToken ({ commit }: TActionContext, payload: any): void {
    commit('SET_ACCESS_TOKEN', payload)
  },
  clearUser ({ commit }: TActionContext): void {
    commit('SET_USER', null)
  },
  clearAccessToken ({ commit }: TActionContext): void {
    commit('SET_ACCESS_TOKEN', null)
  },
  clearAll ({ commit }: TActionContext): void {
    commit('SET_USER', null)
    commit('SET_ACCESS_TOKEN', null)
  }
}

const mutations: TMutation = {
  SET_USER (state: IUserState, payload: any): void {
    state.user = payload
  },
  SET_ACCESS_TOKEN (state: IUserState, payload: any): void {
    state.token = payload
  }
}

const getters: TGetter = {
  getUser: (state: IUserState): any => state.user,
  getToken: (state: IUserState): any => state.token,
  accessToken: (state: IUserState): any => state.token?.accessToken || null,
  tokenExpire: (state: IUserState): any => state.token?.expireIn || 0
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
