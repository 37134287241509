export default {
  path: '/report',
  component: (): any => import('@/views/POS/POS.vue'),
  meta: {
    auth: true,
    title: 'Report',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
  },
  children: [
    {
      path: '',
      name: 'ReportMenu',
      component: (): any => import('@/views/report/pages/ReportMenu.vue'),
      meta: {
        auth: true,
        title: 'รายงาน',
        icon: 'mdi mdi-chart-box',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'report-sale',
      name: 'ReportSale',
      component: (): any => import('@/views/report/pages/ReportSale.vue'),
      meta: {
        auth: true,
        title: 'รายงานการขาย',
        icon: 'mdi mdi-chart-box',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'report-circulation',
      name: 'ReportCirculation',
      component: (): any => import('@/views/report/pages/ReportCirculation.vue'),
      meta: {
        auth: true,
        title: 'รายงานยอดขายสินค้า',
        icon: 'mdi mdi-chart-box',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'report-crm',
      name: 'ReportCrm',
      component: (): any => import('@/views/report/pages/ReportCrm.vue'),
      meta: {
        auth: true,
        title: 'CRM',
        icon: 'mdi mdi-chart-box',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'report-stock',
      name: 'ReportStock',
      component: (): any => import('@/views/report/pages/ReportStock.vue'),
      meta: {
        auth: true,
        title: 'รายงานจำนวนสินค้า',
        icon: 'mdi mdi-chart-box',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'report-daily-sales-summary',
      name: 'ReportDailySalesSummary',
      component: (): any => import('@/views/report/pages/ReportDailySalesSummary.vue'),
      meta: {
        auth: true,
        title: 'สรุปยอดประจำวัน',
        icon: 'mdi mdi-chart-box',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    }
  ]
}
