import { RouteConfig } from 'vue-router'
import { IMenu, ISettingChildMenu, ISettingMenu } from '@/models/Menu.model'
import store from '@/store'

const isAllowedRoles = (item: RouteConfig, role: string): boolean => item?.meta?.allowedRoles.includes(role)

const filterIsMenu = (item: RouteConfig): boolean => {
  if (item.children?.length) {
    return item.children.some((itemChildren: RouteConfig): boolean => filterIsMenu(itemChildren))
  }
  const role = store.getters['Merchant/getUserRoles']
  return !!item?.meta?.title && !!item?.meta?.isMenu && isAllowedRoles(item, role)
}

const filterIsSettingMenu = (item: RouteConfig): boolean => {
  if (item.children?.length) {
    return item.children.some((itemChildren: RouteConfig): boolean => filterIsSettingMenu(itemChildren))
  }
  const role = store.getters['Merchant/getUserRoles']
  return !!item?.meta?.title && !!item?.meta?.isSettingMenu && isAllowedRoles(item, role)
}

const mapMenus = (items: RouteConfig[]): IMenu[] => {
  const menus: IMenu[] = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.children?.length && !!item?.meta?.title && !!item?.meta?.isMenu) {
      menus.push({
        title: item?.meta?.title || '',
        icon: item?.meta?.icon || '',
        hiddenIcon: item?.meta?.hiddenIcon || '',
        subMenus: mapMenus(item.children),
        to: item?.name || '',
        group: item?.meta?.group || null,
        bottom: item?.meta?.bottom || false
      })
    } else if (item.children?.length) {
      const childrenItems = item.children
        .filter((childrenItem: RouteConfig): boolean => filterIsMenu(childrenItem))
      const childrenMenu = mapMenus(childrenItems)
      menus.push(...childrenMenu)
    } else {
      menus.push({
        title: item?.meta?.title || '',
        icon: item?.meta?.icon || '',
        hiddenIcon: item?.meta?.hiddenIcon || '',
        to: item?.name || '',
        group: item?.meta?.group || null,
        bottom: item?.meta?.bottom || false
      })
    }
  }
  return menus
}

const mapSettingMenu = (items: RouteConfig): ISettingMenu[] => {
  const menus: ISettingMenu[] = []
  if (!items?.children?.length) {
    return []
  }

  const mapSettingChildMenu = (children: any[]): ISettingChildMenu[] => {
    const childFilter = children
      .filter((child: any): boolean => filterIsSettingMenu(child))
      .map((child: any): ISettingChildMenu => ({
        title: child?.meta?.subtitle || child?.meta?.title || '',
        to: child?.name || ''
      }))
    return childFilter
  }

  for (let i = 0; i < items?.children?.length; i++) {
    const item = items?.children[i]
    if (item?.children?.length) {
      menus.push({
        title: item?.meta?.title || '',
        icon: item?.meta?.icon || '',
        items: mapSettingChildMenu(item?.children)
      })
    }
  }
  const menuFilter = menus.filter((menu: any): boolean => menu.items.length)
  return menuFilter
}

export function createMenus (items: RouteConfig[]): IMenu[] {
  const routeFilter = items
    .filter((item: RouteConfig): boolean => filterIsMenu(item))
  return mapMenus(routeFilter)
}

export function createSettingMenu (items: RouteConfig[]): ISettingMenu[] {
  const routeFilter = items
    .filter((item: RouteConfig): boolean => filterIsSettingMenu(item))
  return mapSettingMenu(routeFilter[0])
}

export default {
  createMenus,
  createSettingMenu
}
