export default {
  path: 'user',
  component: (): any => import('@/views/setting/pages/user/User.vue'),
  meta: {
    title: 'พนักงาน',
    auth: true,
    icon: 'mdi-card-account-details-outline',
    allowedRoles: ['SUPER_ADMIN']
  },
  children: [
    {
      path: '',
      name: 'SettingUser',
      component: (): any => import('@/views/setting/pages/user/pages/SettingUser.vue'),
      meta: {
        auth: true,
        isSettingMenu: true,
        title: 'จัดการผู้ใช้งาน',
        allowedRoles: ['SUPER_ADMIN']
      }
    },
    {
      path: 'create',
      name: 'SettingUserCreate',
      component: (): any => import('@/views/setting/pages/user/pages/SettingUserCreate.vue'),
      meta: {
        auth: true,
        title: 'เพิ่มผู้ใช้งาน',
        allowedRoles: ['SUPER_ADMIN']
      }
    },
    {
      path: 'edit/:id',
      name: 'SettingUserEdit',
      component: (): any => import('@/views/setting/pages/user/pages/SettingUserEdit.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขผู้ใช้งาน',
        allowedRoles: ['SUPER_ADMIN']
      }
    },
    {
      path: 'details/:id',
      name: 'SettingUserDetails',
      component: (): any => import('@/views/setting/pages/user/pages/SettingUserDetails.vue'),
      meta: {
        auth: true,
        title: 'แก้ไขผู้ใช้งาน',
        allowedRoles: ['SUPER_ADMIN']
      }
    }
  ]
}
