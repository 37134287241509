export default {
  path: '/auth',
  component: (): any => import('@/views/auth/Auth.vue'),
  meta: {
    title: 'auth'
  },
  children: [
    {
      path: 'login',
      name: 'Login',
      component: (): any => import('@/views/auth/pages/Login.vue'),
      meta: {
        title: 'เข้าสู่ระบบ',
        layout: 'auth'
      }
    },
    {
      path: 'logout',
      name: 'Logout',
      component: (): any => import('@/views/auth/pages/Logout.vue'),
      meta: {
        title: 'ออกจากระบบ',
        layout: 'auth'
      }
    },
    {
      path: 'register',
      name: 'Register',
      component: (): any => import('@/views/auth/pages/Register.vue'),
      meta: {
        title: 'เข้าสู่ระบบครั้งแรก',
        layout: 'auth',
        auth: false
      }
    },
    {
      path: 'login-first-time',
      name: 'LoginFirstTime',
      component: (): any => import('@/views/auth/pages/LoginFirstTime.vue'),
      meta: {
        title: 'เข้าสู่ระบบ',
        layout: 'auth',
        auth: false
      }
    },
    {
      path: 'validate-email',
      name: 'ValidateEmail',
      component: (): any => import('@/views/auth/pages/ValidateEmail.vue'),
      meta: {
        title: 'เข้าสู่ระบบ',
        layout: 'auth',
        auth: false
      }
    },
    {
      path: 'forgot-password',
      name: 'ForgotPassword',
      component: (): any => import('@/views/auth/pages/ForgotPassword.vue'),
      meta: {
        title: 'ลืมรหัสผ่าน',
        layout: 'auth',
        auth: false
      }
    },
    {
      path: 'reset-password',
      name: 'ResetPassword',
      component: (): any => import('@/views/auth/pages/ResetPassword.vue'),
      meta: {
        title: 'ตั้งรหัสผ่านใหม่',
        layout: 'auth'
      }
    }
  ]
}
