import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { IWarehouseState, IRootState } from '@/models/Store.model'

type TAction = ActionTree<IWarehouseState, IRootState>
type TActionContext = ActionContext<IWarehouseState, IRootState>
type TMutation = MutationTree<IWarehouseState>
type TGetter = GetterTree<IWarehouseState, IRootState>

const state: IWarehouseState = {
  warehouse: {
    // id: 31,
    // note: '',
    // address: '',
    // district: '',
    // subDistrict: '',
    // province: '',
    // postalCode: ''
  },
  warehouseItems: []
}

const actions: TAction = {
  setWarehouse ({ commit }: TActionContext, payload: any): void {
    commit('SET_WAREHOUSE', payload)
  },
  setWarehouseItems ({ commit }: TActionContext, payload: any[]): void {
    commit('SET_WAREHOUSE_ITEMS', payload)
  },
  clearWarehouse ({ commit }: TActionContext): void {
    commit('SET_WAREHOUSE', {})
    commit('SET_WAREHOUSE_ITEMS', [])
  }
}

const mutations: TMutation = {
  SET_WAREHOUSE (state: IWarehouseState, payload: any): void {
    state.warehouse = payload
  },
  SET_WAREHOUSE_ITEMS (state: IWarehouseState, payload: any[]): void {
    state.warehouseItems = payload
  }
}

const getters: TGetter = {
  getWarehouse: (state: IWarehouseState): any => state.warehouse,
  getWarehouseId: (state: IWarehouseState): any => state.warehouse?.id,
  getWarehouseItems: (state: IWarehouseState): any[] => state.warehouseItems,
  getWarehouseItemsExcludeSelf: (state: IWarehouseState): any => {
    const result = state.warehouseItems.filter((warehouse: any): boolean => warehouse.id !== state.warehouse?.id)
    return result
  },
  getWarehouseItemIds: (state: IWarehouseState): any[] => state.warehouseItems.map((item: any): string => item.id)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
