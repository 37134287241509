export default {
  path: 'product',
  component: (): any => import('@/views/setting/pages/product/Product.vue'),
  meta: {
    title: 'เกี่ยวกับสินค้า',
    auth: true,
    icon: 'mdi-package-variant-closed',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN']
  },
  children: [
    {
      path: 'branch',
      name: 'SettingBranch',
      component: (): any => import('@/views/setting/pages/product/pages/branch/SettingBranch.vue'),
      meta: {
        title: 'สาขา',
        subtitle: 'สาขาหรือคลังสินค้า',
        auth: true,
        isSettingMenu: true,
        icon: 'mdi-package-variant-closed',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      },
      redirect: {
        name: 'SettingBranchList'
      },
      children: [
        {
          path: '',
          name: 'SettingBranchList',
          component: (): any => import('@/views/setting/pages/product/pages/branch/pages/SettingBranchList.vue'),
          meta: {
            auth: true,
            isSettingMenu: true,
            title: 'สาขา',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'create',
          name: 'SettingBranchCreate',
          component: (): any => import('@/views/setting/pages/product/pages/branch/pages/SettingBranchCreate.vue'),
          meta: {
            auth: true,
            title: 'เพิ่มสาขา',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'edit/:id',
          name: 'SettingBranchEdit',
          component: (): any => import('@/views/setting/pages/product/pages/branch/pages/SettingBranchEdit.vue'),
          meta: {
            auth: true,
            title: 'แก้ไขสาขา',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'details/:id',
          name: 'SettingBranchDetails',
          component: (): any => import('@/views/setting/pages/product/pages/branch/pages/SettingBranchDetails.vue'),
          meta: {
            auth: true,
            title: 'รายละเอียดสาขา',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        }
      ]
    },
    {
      path: 'category',
      name: 'SettingCategory',
      component: (): any => import('@/views/setting/pages/product/pages/SettingCategory.vue'),
      meta: {
        auth: true,
        isSettingMenu: true,
        title: 'หมวดหมู่สินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'tag',
      name: 'SettingTag',
      component: (): any => import('@/views/setting/pages/product/pages/SettingTag.vue'),
      meta: {
        auth: true,
        isSettingMenu: true,
        title: 'Tag สินค้า',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
