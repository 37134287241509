import ProductRouter from '../Product.router'
import StockExportRouter from './StockExport.router'
import StockImportRouter from './StockImport.router'
import StockOverviewRouter from './StockOverview.router'

export default {
  path: '/stock',
  component: (): any => import('@/views/stock/Stock.vue'),
  meta: {
    title: 'สินค้าและสต็อกสินค้า',
    auth: true,
    isMenu: true,
    icon: 'mdi-warehouse',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
  },
  children: [
    ProductRouter,
    StockOverviewRouter,
    StockImportRouter,
    StockExportRouter
  ]
}
