<template>
  <v-app id="auth">
    <v-fade-transition>
      <router-view />
    </v-fade-transition>
    <Loading />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import Loading from '@/components/Loading.vue'

export default Vue.extend({
  name: 'LayoutAuth',
  components: {
    Loading
  }
})
</script>

<style lang="scss" scoped>
#auth {
  background: var(--v-primary-base);
}
</style>
