import Vue from 'vue'

export interface IFilters {
  numberFormat (_number: string | number): string
  numberFormat2Decimal (_number: string | number): string
}

function numberParseFloat (_number: string | number): number {
  if (typeof _number === 'string') {
    return parseFloat(_number)
  }
  return _number
}

export function numberFormat (_number: string | number = 0): string {
  const num: number = numberParseFloat(_number)
  if (num === 0 || num) {
    return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
}

export function numberFormat2Decimal (_number: string | number = 0): string {
  const num: number = numberParseFloat(_number)
  if (num === 0 || num) {
    return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
}

const filters: IFilters = {
  numberFormat,
  numberFormat2Decimal
}

Vue.prototype.$filters = filters

export default filters
