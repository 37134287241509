import SettingAdminRouter from './SettingAdmin.router'
import SettingUserRouter from './SettingUser.router'
import SettingProductRouter from './SettingProduct.router'
import SettingOtherRouter from './SettingOther.router'

export default {
  path: '/setting',
  component: (): any => import('@/views/setting/Setting.vue'),
  meta: {
    title: 'การตั้งค่า',
    auth: true
  },
  children: [
    {
      path: '',
      name: 'Setting',
      component: (): any => import('@/views/setting/pages/SettingMenu.vue'),
      meta: {
        auth: true,
        isMenu: true,
        bottom: true,
        title: 'การตั้งค่า',
        icon: 'mdi-tune',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    SettingAdminRouter,
    SettingUserRouter,
    SettingProductRouter,
    SettingOtherRouter
  ]
}
