import { IWarehousePayload } from '@/models/request/WarehouseReq.model'
import HttpRequest from '../HttpRequest'
import { IPaginationResponse, ISuccessResponse } from '@/models/response/Response.model'
import { IWarehouse } from '@/models/stock/Warehouse.model'

export interface IWarehouseProvider {
  getWarehouse (payload: any): Promise<IPaginationResponse<IWarehouse>>
  getWarehouseById (id: number | string): Promise<ISuccessResponse<IWarehouse>>
  createWarehouse (payload: IWarehousePayload): Promise<any>
  updateWarehouse (id: string, payload: IWarehousePayload): Promise<any>
  deleteWarehouse (id: string): Promise<void>
}

class WarehouseProvider extends HttpRequest implements IWarehouseProvider {
  private urlPrefix: string = '/v1/management/warehouse'

  public async getWarehouse (payload: any): Promise<IPaginationResponse<IWarehouse>> {
    this.setMerchantAuthHeader()
    const response: IPaginationResponse<IWarehouse> = await this.get(`${this.urlPrefix}`, {
      ...payload
    })
    return response
  }

  public async getWarehouseById (id: number | string): Promise<ISuccessResponse<IWarehouse>> {
    this.setMerchantAuthHeader()
    const response: IPaginationResponse<IWarehouse> = await this.get(`${this.urlPrefix}/${id}`)
    return response
  }

  public async createWarehouse (payload: IWarehousePayload): Promise<any> {
    this.setMerchantAuthHeader()
    const response: any = await this.post(`${this.urlPrefix}`, payload)
    return response
  }

  public async updateWarehouse (id: string, payload: IWarehousePayload): Promise<any> {
    this.setMerchantAuthHeader()
    const response: any = await this.put(`${this.urlPrefix}/${id}`, payload)
    return response
  }

  public async deleteWarehouse (id: string): Promise<void> {
    this.setMerchantAuthHeader()
    const response: any = await this.delete(`${this.urlPrefix}/${id}`)
    return response
  }
}

export default WarehouseProvider
